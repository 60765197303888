import React from 'react'
import Template from "../components/Template";
import '../components/test.sass'

export default function NotFound() {

  return (
  <Template
    title="404 Not Found"
  >
        <div className="section">
          <div className="container has-text-left">
            <div className="title has-text-white">
              Page Not Found
            </div>
            <div className="subtitle has-text-white">
              You seem lost
            </div>
          </div>
        </div>
  </Template>
  )
}